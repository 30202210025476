import { CameraOutlined, DownloadOutlined } from '@ant-design/icons'
import { Button, Card, Descriptions, Flex, Image, notification } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import apiClient, { handleApiError } from '../../../services/apiClient'
import downloadPDF from './downloadPDF'

const PatientInformation = ({
  patient,
  physicalActivityLevels,
  communes,
  healthInsurances,
  professions,
}) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const fileInputRef = useRef(null)

  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    console.log('File:', file)
    if (!file) return

    try {
      const response = await apiClient.post(`/patients/${id}/request-signed-url`, {
        fileName: file.name,
        contentType: file.type,
      })

      const signedUrl = response.data.signedUrl
      console.log('Signed URL received')

      const uploadResponse = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      if (uploadResponse.status === 200) {
        console.log('File uploaded successfully')
        await apiClient.put(`/patients/${id}`, {
          profilePictureFilename: `patients/${id}/${file.name}`
        })
        console.log('Profile picture filename updated')
        window.location.reload()

      } else {
        notification.error({
          message: 'Error',
          description: 'No se pudo subir el archivo'
        })
        console.error('Error uploading file:', uploadResponse.statusText)
      }
    } catch (error) {
      handleApiError({ error })
      console.error('Error:', error)
    }
  }


  return (
    <Card style={{ marginBottom: '6vh' }}>
      <Flex align="top" justify="space-between">
        <h1>Información del paciente</h1>
        <Flex>
          <Image
            width={100}
            src={patient.profilePictureUrl}
          />
          <Flex vertical justify='space-between' style={{ marginLeft: 30 }}>
            <Button
              icon={<CameraOutlined />}
              onClick={() => fileInputRef.current.click()}
            >
              Actualizar foto
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button
              icon={<DownloadOutlined />}
              loading={loading}
              onClick={async () => {
                setLoading(true)
                await downloadPDF(`/patients/${id}/generate-pdf`)
                setLoading(false)
              }}
            >
              Descargar perfil
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Descriptions title="Detalles" bordered size="default">
        <Descriptions.Item label="ID">{patient.id}</Descriptions.Item>
        <Descriptions.Item label="Nombres">
          {patient.firstName} {patient.secondName}
        </Descriptions.Item>
        <Descriptions.Item label="Apellidos">
          {patient.firstLastName} {patient.secondLastName}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de Nacimiento">
          {dayjs(patient.dateOfBirth).format('D [de] MMMM [del] YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Edad">{patient.age} años</Descriptions.Item>
        <Descriptions.Item label="Género">{patient.sex}</Descriptions.Item>
        <Descriptions.Item label="Nivel de Actividad Física">
          {
            physicalActivityLevels.find(
              level =>
                String(level.id) === String(patient.physicalActivityLevelId)
            )?.level
          }
        </Descriptions.Item>
        <Descriptions.Item label="Comuna">
          {
            communes.find(
              comuna => String(comuna.id) === String(patient.communeId)
            )?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label="Previsión">
          {
            healthInsurances.find(
              insurance =>
                String(insurance.id) === String(patient.healthInsuranceId)
            )?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label="Profesión">
          {
            professions.find(
              profession =>
                String(profession.id) === String(patient.professionId)
            )?.name
          }
        </Descriptions.Item>
        <Descriptions.Item label="Teléfono">
          {patient.phoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Correo Electrónico">
          {patient.email}
        </Descriptions.Item>
        <Descriptions.Item label="Dirección">
          {patient.address}
        </Descriptions.Item>
        <Descriptions.Item label="Referido por">
          {patient.referedBy}
        </Descriptions.Item>

        <Descriptions.Item label="RUT">{patient.rut}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

export default PatientInformation
